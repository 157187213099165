import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import SocialWrapper from "../../components/SocialWrapper";
import H from "../../components/Headline";
import Banner from "../../components/Banner";
import EmailSignupSimplePlugin from "../../components/EmailSignupSimplePlugin";
import { experienceYears } from "../../components/Helpers";
import { useAlternateLangs } from "../../components/Hreflangs";

const StyledMiddle = styled.div`
  margin: 0vmin 1vmin;
  .container {
    margin: 7vmin 0vmin;
    display: flex;
  }

  h1 {
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
  }

  .content {
    width: 60%;
    margin-right: 1rem;
  }

  .image-wrapper {
    margin: 0rem 0rem auto 0rem;
    width: 30%;

    & .gatsby-image-wrapper {
      box-shadow: 0px 0px 3px var(--foreground);
      margin-bottom: 1rem;
    }
  }
  @media all and (max-width: 540px) {
    justify-content: center;
    .container {
      flex-direction: column-reverse;
    }

    .content {
      width: 100%;
      margin-right: none;
    }

    .image-wrapper {
      margin: 1rem auto 4rem auto;
      width: 60%;
    }
  }
`;

const StyledSecondaryContent = styled.div`
  grid-area: secondary;
  background: orange;
  display: flex;

  .card {
    border-top: 1px solid var(--foreground);
    &:first-child {
      border-right: 1px solid var(--foreground);
    }
  }

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
`;

// Breadcrumb data

const breadCrumbLevels = {
  Hjem: "/no",
  "Om meg": "/no/om"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/about"
);

const About = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO title="Om Matthias Kupperschmidt" lang="no" alternateLangs={alternateLangs} />
      <MainContent maxWidth="700">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <div className="content">
              <H as="h1">Hei, jeg er Matthias</H>
              <p>Jeg optimaliserer nettsider for Googles søkeresultater og implementerer analytiske oppsett.</p>
              <p>
                Jeg er tysk, bor i København, Danmark. Jeg har jobbet i {experienceYears} år i flere digitale byråer
                før jeg begynte å jobbe som <Link to="/no/google-analytics-frilanser">frilanser for Google Analytics</Link> og SEO.
              </p>
              <p>
                Ifølge flere personlighetstester er jeg veldig analytisk, rasjonell og strukturert, samtidig som jeg er
                målrettet og pragmatisk.
              </p>
              <H as="h2">Min historie</H>
              <p>
                Da jeg var 14, bygde jeg min første nettside for å bytte filmer på skolegården. Det var den beste måten å vise min
                filmsamling til klassekameratene mine. Jeg husker at jeg laget de første HTML-filene med Microsoft Word og senere
                med en editor kalt "Phase5".
              </p>
              <p>
                Senere bygde jeg min egen nettbutikk for bordfotball (eller "fooseball" som vi kaller det), kalt
                "Kickerkult". Jeg var 18 år og en ambisiøs, wannabe-profesjonell bordfotballspiller, så det var en god
                kombinasjon av to interesser jeg hadde.
                <br /> I løpet av den tiden spilte jeg mange turneringer i Berlin og over hele Tyskland. Samtidig administrerte jeg
                nettbutikken min, lærte å sende nyhetsbrev på e-post og begynte med SEO.
                <br />
                Til slutt ble jeg ganske hekta på nettet og dets muligheter og ønsket å fokusere karrieren min på
                det.
              </p>
              <p>
                I dag hjelper jeg selskaper i forskjellige størrelser med å komme til toppen av Googles søkeresultater og å
                spore brukeratferd - uansett hvor i verden selskapet er fra.
              </p>
              <p>
                Og i tillegg skriver jeg om SEO og analytiske emner på <Link to="/no/blogg">bloggen min</Link>.
              </p>
              <H as="h2">
                Hvilken by er den neste Silicon Valley?
                <br />
                Din!
              </H>
              <p>
                Jeg er en sterk tro på at man ikke må flytte til de store, gloriøse byene for å oppnå en flott karriere.
                Gjennom internett finner moderne selskaper, fagfolk og likesinnede hverandre og samarbeider - uavhengig av geografisk avstand.
              </p>
            </div>
            <div className="image-wrapper">
              <Img clickable="false" src="about/matthias-kupperschmidt-about-me-portrait.jpeg" alt="matthias kupperschmidt" />
              <SocialWrapper align="center" twitter="mattzipan" linkedin="matthiaskupperschmidt" github="mattzipan" />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
      <StyledSecondaryContent>
        <Banner>
          <div className="banner__header serif headline">La oss holde kontakten!</div>
          <div className="serif">
            Ikke bekymre deg, jeg vil knapt sende deg e-post. Meld deg på her for å være sikker på å høre fra meg når jeg har noe viktig å dele.
          </div>
          <div className="emailwrapper">
            <EmailSignupSimplePlugin />
          </div>
        </Banner>
        <Banner>
          {/* <div className="banner__header serif headline">
                “Jeg trenger hjelp med SEO eller analyse”
              </div>
              <div className="serif">
                Ikke bekymre deg, jeg vil knapt sende deg e-post. Meld deg på her for å være sikker på å
                høre fra meg når jeg har noe viktig å dele.
              </div> */}
        </Banner>
      </StyledSecondaryContent>
    </React.Fragment>

  </Layout>
);

export default React.memo(About);